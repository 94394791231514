import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginForm } from "../index";
import Slider from "react-slick";

import AuthContext from "../../../../context/autenticacion/authContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import zIndex from "@mui/material/styles/zIndex";
import imgX from "../../../../assets/img/Sello-BCX.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useNotification } from "../../../../context/notificationbar/notificationBarContext";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const Section1 = (props) => {
  const { onSubmit, loading, error } = props;
  const { barHeight } = useNotification();
  const authContext = useContext(AuthContext);
  const { dataBlock, limpiarVariablesAccountBlock } = authContext;
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [isPlayed, setIsPlayed] = useState(true);
  let sliderRef = useRef(null);
  const myRef = useRef(null);

  // Función para calcular marginTop
  const calculateMarginTop = (barHeight, defaultValue) =>
    barHeight !== 0 ? defaultValue : 0;

  const pause = () => {
    if (isPlayed) {
      setIsPlayed(false);
      sliderRef.current.slickPause();
    } else {
      setIsPlayed(true);
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  };

  const assignTabIndex = () => {
    if (
      sliderRef.current &&
      sliderRef.current.innerSlider &&
      sliderRef.current.innerSlider.list
    ) {
      const dotButtons =
        sliderRef.current.innerSlider.list.parentNode.querySelectorAll(
          ".slick-dots button"
        );
      dotButtons.forEach((button, index) => {
        button.tabIndex = -1;
      });
    }
  };

  useEffect(() => {
    assignTabIndex();
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(getWidth());
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [window.screen.width]);

  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, [windowWidth]);

  const renderReactComponent = (rootElement) => {
    import("react-dom").then((ReactDOM) => {
      ReactDOM.render(
        <div onClick={pause} style={{ cursor: "pointer" }} id="slidesPlay">
          {isPlayed ? (
            <PauseIcon fontSize="medium" sx={{ color: "#a40023" }} />
          ) : (
            <PlayArrowIcon fontSize="medium" sx={{ color: "#a40023" }} />
          )}
        </div>,
        rootElement
      );
    });
  };

  useEffect(() => {
    if (windowWidth > 959) {
      const listItem = document.createElement("li");
      renderReactComponent(listItem);
      const list = document.querySelector("ul.slick-dots");

      if (document.getElementById("slidesPlay") && list && list.lastChild) {
        const lastChild = list.lastChild;
        const slidesPlayDiv = lastChild.querySelector("div#slidesPlay");
        if (slidesPlayDiv) list.removeChild(list.lastChild);
      }
      list.appendChild(listItem);
    } else {
      const list = document.querySelector("ul.slick-dots");
      if (document.getElementById("slidesPlay") && list && list.lastChild) {
        const lastChild = list.lastChild;
        const slidesPlayDiv = lastChild.querySelector("div#slidesPlay");
        if (slidesPlayDiv) list.removeChild(list.lastChild);
      }
    }
  }, [isPlayed, windowWidth]);

  return (
    <section className="section1" ref={myRef}>
      <div className="banner-container">
        <Slider ref={sliderRef} {...settings}>
          {/* <div className="banner-section bg-baner-21">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div className="box-baner-21" style={{ marginTop: `${calculateMarginTop(barHeight, 10)}px` }}>
                  <p className="txt-title1-banner-21">
                    Somos
                  </p>
                  <p className="txt-title2-banner-21">
                    líderes en el Fondo 2
                  </p>
                  <p className="txt-title3-banner-21">
                    al cierre de 2024
                  </p>
                  <p className="txt-title4-banner-21">Te ofrecemos&nbsp;<span>la mayor rentabilidad en el Fondo 2</span></p>
                  <p className="txt-title4-banner-21">en el que más del 90% de afiliados al SPP invierte.</p>
                </div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner_lider_rentabilidad_fondo2&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Cámbiate a Habitat
              </button>
              <div className="txt-subtitle-banner-21">
                *Fuente legal: Boletín Estadístico Mensual SBS diciembre 2024.
                Rentabilidad Nominal y Real acumulada del Fondo de Pensiones Tipo 2 por AFP.
                Diciembre 2024 - diciembre 2013. <a href="www.sbs.gob.pe">www.sbs.gob.pe.{" "}</a>
                <span>
                  La rentabilidad de los distintos tipos de Fondos de Pensiones es variable,
                  su nivel en el futuro puede cambiar en relación con la rentabilidad pasada.
                </span>
              </div>
            </div>
          </div>
          <div className="banner-section bg-baner-22">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div className="box-baner-22" style={{ marginTop: `${calculateMarginTop(barHeight, 10)}px` }}>
                  <p className="txt-title1-banner-22">
                    Somos
                  </p>
                  <p className="txt-title2-banner-22">
                    líderes en el Fondo 3
                  </p>
                  <p className="txt-title3-banner-22">
                    al cierre de 2024
                  </p>
                  <p className="txt-title4-banner-22">Gestionamos el &nbsp;<span>Fondo 3 más grande del mercado</span></p>
                </div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner_lider_rentabilidad_fondo3&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Cámbiate a Habitat
              </button>
              <div className="txt-subtitle-banner-22">
                *Fuente legal: Boletín Estadístico Mensual SBS diciembre 2024.
                Rentabilidad Nominal y Real acumulada del Fondo de Pensiones Tipo 3 por AFP.
                Diciembre 2024 - diciembre 2013. <a href="www.sbs.gob.pe">www.sbs.gob.pe.{" "}</a>
                <span>
                  La rentabilidad de los distintos tipos de Fondos de Pensiones es variable,
                  su nivel en el futuro puede cambiar en relación con la rentabilidad pasada.
                </span>
              </div>
            </div>
          </div> */}
          {/* 1 */}
          <div className="banner-section bg-baner-20">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div class="overlay"></div>
                <div class="overlay-izo"></div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                data-event-option="cambiate a habitat"
                data-event-section="banner principal"
                data-event-description
                data-event-name="banner_click"
                id="clic_button_home_s1"
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner&utm_campaign=lider_servicio",
                    "__blank"
                  )
                }
              >
                Asegura tu futuro hoy
              </button>
            </div>
          </div>
          {/* 2 */}
          <div className="banner-section bg-baner-19">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div className="box-baner-19" style={{ marginTop: `${calculateMarginTop(barHeight, 21)}px` }}>
                  <p className="txt-title1-banner-19">
                    NUEVAMENTE DENTRO DEL
                  </p>
                  <p className="txt-title2-banner-19">
                    TOP 100
                  </p>
                  <p className="txt-title3-banner-19">
                    DE EMPRESAS
                  </p>
                  <p className="txt-title4-banner-19">CON MEJOR REPUTACIÓN</p>
                  <p className="txt-title5-banner-19">CORPORATIVA EN EL PERÚ</p>
                </div>
              </div>
              <div className="svg-disclaimer"></div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                data-event-option="cambiate a habitat"
                data-event-section="banner principal"
                data-event-description
                data-event-name="banner_click"
                id="clic_button_home_s2"
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner_merco_reputacion&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Conoce nuestra rentabilidad
              </button>
            </div>
          </div>
          {/* 3 */}
          <div className="banner-section bg-baner-13">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div className="box-baner-13" style={{ marginTop: `${calculateMarginTop(barHeight, 5)}px` }}>
                  <p className="txt-title1-banner-13">
                    Recuerda que es importante
                  </p>
                  <p className="txt-title2-5-banner-13">
                    mantener tus datos actualizados
                  </p>
                  <p className="txt-title2-banner-13">mantener tus datos</p>
                  <p className="txt-title2-banner-13">actualizados</p>
                </div>
                <p className="txt-banner-13">Hazlo en Mi Habitat Digital</p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 80 }}
                tabIndex={"-1"}
                data-event-option="cambiate a habitat"
                data-event-section="banner principal"
                data-event-description
                data-event-name="banner_click"
                id="clic_button_home_s3"
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/#/mi-habitat-digital/empezar",
                    "__blank"
                  )
                }
              >
                Más información
              </button>
            </div>
          </div>
          {/* <div className="banner-section bg-baner-17">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div className="box-baner-17" style={{ marginTop: `${calculateMarginTop(barHeight, 5)}px` }}>
                  <p className="txt-banner-17">Conoce todo sobre la</p>
                  <p className="txt-title1-banner-17">Ley de modernización del</p>
                  <p className="txt-title2-banner-17">Sistema Previsional Peruano</p>
                </div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100 }}
                tabIndex={"-1"}
                data-event-option="cambiate a habitat"
                data-event-section="banner principal"
                data-event-description
                data-event-name="banner_click"
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/nueva-reforma-del-sistema-de-pensiones/",
                    "__blank"
                  )
                }
              >
                Más información
              </button>
            </div>
          </div> */}
        </Slider>
        {isMobile && (
          <div
            onClick={pause}
            style={{ cursor: "pointer" }}
            className="slideMobile"
          >
            {isPlayed ? (
              <PauseIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            )}
          </div>
        )}

        <div className="desktop-login">
          <LoginForm
            sendRequest={onSubmit}
            loading={loading}
            error={error}
            dataBlock={dataBlock}
            limpiarVariablesAccountBlock={limpiarVariablesAccountBlock}
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;
